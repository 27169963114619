<template>
    <div class="checkTask">
      <!-- top -->
      <el-breadcrumb separator="/">
          <el-breadcrumb-item>专家管理</el-breadcrumb-item>
          <el-breadcrumb-item>申请列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="tableBox">
          <!-- blue_title -->
          <div class="blueTitle" style="margin-left:12px;">申请列表</div>
          <div class="tableTop">
             <div class="table_l">
                <div :class="{'bolboxl':isActive==1,'noacitve':isActive!=1}" style="width:46px !important" @click="getAll">全部</div>
                <div :class="{'bolboxl':isActive==2,'noacitve':isActive!=2}" @click="noDo">待处理</div>
                <div :class="{'bolboxl':isActive==3,'noacitve':isActive!=3}" @click="haveDo">已处理</div>
             </div>
            <div class="table_r">
                <el-date-picker
                    style="width:220px !import"
                    v-model="seachDate"
                    value-format="yyyy-MM-dd"
                    type="date"
                    @change="seachBydate"
                    placeholder="选择查询创建日期">
                </el-date-picker>
                <!-- 地区 -->
                <el-cascader v-model="checkCity"  @change="setCity" :options="district" placeholder="请选择地区" clearable style="width:224px !important;margin-left: 24px;"></el-cascader>
                <!-- 全部 -->
                <el-select v-model="jobTitle" @change="selectResult" style="width: 152px !important;margin-left: 24px;">
                    <el-option
                        v-for="item in resultOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <div class="search">
                    <el-input
                        placeholder="请输入内容"
                        prefix-icon="el-icon-search"
                        @change="search"
                        v-model="seachcon"
                        class="elSerch"
                        >
                        <template slot="append" style="background-color: #00CCCC;cursor:pointer">搜索</template>
                    </el-input>
                </div>
            </div>
          </div>
           <ApplyListTable ref="apply"/>
      </div>
    </div>
</template>
<script>

import ApplyListTable from '@/components/table/applyListTable.vue'
import { district } from '@/assets/js/cityCode.js'
export default {
    beforeRouteLeave(to,from,next){
        from.meta.keepAlive = false;
        next();
    },
    components:{
        ApplyListTable,
    },
    data(){
        return{
            district,
            checkCity:null,
            seachDate:null,//日期搜索
            jobTitle:null,//职位
            seachcon:null,
            //点击样式控制
           isActive:1,
           //预约状态选择
           resultOption:[ {
                            label:'全部',
                            value: null
                        }, {
                            label:'康复师',
                            value: '康复师',
                        },
                        {
                            label:'支具师',
                            value:"支具师",
                        },
                        {
                            label:'其他',
                            value:"其他",
                        }
                        ],
        }
    },
    created(){
        //
    },
  
    methods:{
        //全部
        getAll(){
            this.isActive=1;
            this.isActiveStatus= null;
            this.$refs.apply.getData(1,10,null,null,null,null,null)
            
        },
        noDo(){
            this.isActive=2;
            this.isActiveStatus= "处理中";
            this.$refs.apply.getData(1,10,null,null,null,null,'申请中');
            
        },
        haveDo(){
            this.isActive=3;
            this.isActiveStatus= "已处理";
            this.$refs.apply.getData(1,10,null,null,null,null,'已通过');
           
        },
        //职位选择
        selectResult(e){
            console.log("职位选择",e,this.jobTitle);
        },
        //日期选择
        seachBydate(e){
            this.$refs.apply.getData(1,10,e)
           //console.log("日期选着",e,this.seachDate);
        },
        //地区选着
        setCity(e){
            this.$refs.apply.getData(1,10,null,e[0],e[1])
            //console.log("地区选着",e,this.checkCity);
        },
        search(e){
            this.$refs.apply.getData(1,10,null,null,null,e)
            //console.log("搜索",e,this.seachcon);
        }
        
    }
}
</script>
<style scoped lang="stylus">
//change elemen-ui style
//面包屑样式
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }
//下拉框icon样式
/deep/.el-input__icon {
    line-height: 2.4 !important;
}
/deep/.el-input__inner{
    border-radius: 4px 0 0 4px !important 
}
//搜索框样式--边框颜色
.elSerch>>>.el-input__inner
    border: 1px solid #00cccc !important
    width: 200px
//搜索框样式--高度
/deep/.el-input__inner{
    height: 32px;
  }
//搜索框样式--搜索部分样式
/deep/.el-input-group__append{
    border-block-color:#00CCCC !important;
    color: #fff !important;
    background-color: #00CCCC !important;
    border: 0 !important;
  }


//component style
.blueTitle
  font-size: 18px 
  color: #353535
  display: flex
  align-items: center 
  position: relative
  &::before
    width: 3px
    height: 18px
    content: ""
    position: absolute
    border-radius: .03rem
    left: -10px
    bottom:2px
    background-color: #00CCCC
.bolboxl
    width: 64px  
    font-size:16px
    color: #353535
    margin-left: 20px
    cursor pointer
    text-align: center
    position: relative 
    &::after 
        content: '' 
        position: absolute 
        width: 100% !important
        left 0 !important
        bottom: -2px 
        height: 4px 
        border-radius: 5px
        background-color:#00CCCC!important
.noacitve 
    width: 68px  
    font-size:16px
    color: #353535
    margin-left: 20px
    cursor pointer
    text-align: center
    opacity .4


.seachText
    font-size: 14px
    color: #AEAEAE

//normal style
.checkTask
    overflow hidden
    .tableBox
        overflow hidden
        margin-top: 16px
        padding: 20px
        background-color: #FFFFFF
        .tableTop
            display: flex
            align-items: center
            margin-top: 20px
            margin-bottom: 30px
            justify-content: space-between
            .table_l
                display: flex
                align-items: center
            .table_r
                display: flex
                align-items: center
                .search
                    height: 32px
                    margin-left: 16px
                    display: flex
                    .secbox
                        width: 88px
                        height: 32px  
                        background-color: #00cccc
                        text-align: center
                        line-height: 32px
                        font-size: 14px
                        color: #fff
                .delete
                    width: 64px
                    height: 32px
                    background-color: #FC5555
                    text-align: center
                    line-height:32px
                    color: #fff
                    font-size: 14px
                    margin-left: 16px
                    cursor pointer
                    border-radius: 4px

            
</style>